<template>
  <div
    class="header container-lg"
    :class="{ 'header--dark': dark }"
  >
    <!-- Burger Menu for mobile screens -->
    <div class="burger-icon">
      <Slide
        v-if="width"
        :close-on-navigation="true"
        :width="`${width}px`"
        class="burger-menu custom-burger-menu"
      >
        <div class="burger-nav">
          <router-link
            class="nav-item"
            to="/"
            :class="{ active: $route.path === '/' }"
          >
            <span>{{ $t('home') }}</span>
          </router-link>
          <router-link
            class="nav-item"
            to="/shop"
            :class="{ active: $route.path === '/brands' }"
          >
            <span>{{ $t('shop') }}</span>
          </router-link>
          <router-link
            class="nav-item"
            to="/services"
            :class="{ active: $route.path === '/services' }"
          >
            <span>{{ $t('treatment') }}</span>
          </router-link>
          <router-link
            class="nav-item"
            to="/contact-us"
          >
            <span>{{ $t('contact') }}</span>
          </router-link>
          <router-link
            class="nav-item"
            to="/cart"
          >
            <span>{{ $t('cart') }}({{ cartItemCount }})</span>
          </router-link>
          <!-- BOOK ONLINE button in the burger menu -->
          <!-- <router-link to="">
            <button class="bookOnlineWhite">
              BOOK ONLINE
            </button>
          </router-link> -->
        </div>
      </Slide>
    </div>

    <div class="header__navigation">
      <!-- Logo -->
      <div class="logo">
        <img
          src="@/assets/images/cielWhite.svg"
          alt="Ciel logo"
        >
<!--        <img-->
<!--          v-else-->
<!--          src="@/assets/images/CIEL.svg"-->
<!--          alt="Ciel logo"-->
<!--        >-->
      </div>

      <!-- Links for desktop screens -->
      <div class="links">
        <router-link
          to="/"
          :class="{ active: $route.path === '/' }"
        >
          <span>{{ $t('home') }}</span>
        </router-link>
        <router-link
          to="/brands"
          :class="{ active: $route.path === '/brands' }"
        >
          <span>{{ $t('shop') }}</span>
        </router-link>
        <router-link
          to="/services"
          :class="{ active: $route.path === '/services' }"
        >
          <span>{{ $t('treatment') }}</span>
        </router-link>
        <router-link
          to="/contact-us"
          :class="{ active: $route.path === '/contact-us' }"
        >
          <span>{{ $t('contact') }}</span>
        </router-link>
        <router-link
          to="/cart"
          :class="{ active: $route.path === '/cart' }"
        >
          <span>{{ $t('cart') }}({{ cartItemCount }})</span>
        </router-link>
      </div>
      <!-- Cart Icon -->
      <!-- <div class="bookAndCart">
        <router-link to="">
          <button class="bookOnlineWhite desktop-bookOnline">
            BOOK ONLINE
          </button>
        </router-link>
      </div> -->
      <div class="language-selector">
        <select
          v-model="selectedLanguage"
          @change="changeLanguage"
        >
          <option value="en" class="lang-option">
            EN
          </option>
          <option value="al" class="lang-option">
            AL
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue3-burger-menu";
import { mapGetters } from "vuex";

export default {
  components: {
    Slide,
  },
  props: {
    dark: {
      type: Boolean,
    },
  },
  data() {
    return {
      width: window.innerWidth,
      selectedLanguage: this.$i18n.locale,
    };
  },
  computed: {
    ...mapGetters("shop", ["getCartItems"]),
    cartItemCount() {
      return this.getCartItems.length;
    },
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      this.$store.commit("language/setLanguage", this.selectedLanguage);
    },
  }
};
</script>

<style lang="scss">
.header {
  z-index: 1;
  position: relative;
}
.header__navigation {
  height: var(--header-height);
  margin-top: var(--header-top-gap);
  border-radius: 13px;
  // position: absolute;
  background-color: black;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--dark .header__navigation {
  background-color: #89d0f0;

  .links a {
    color: white;
  }
  .bookAndCart {
    .bookOnlineWhite {
      background-color: white;
      color: #89d0f0;
    }
  }
}

.bm-burger-button {
  top: 50%;
  transform: translateY(-50%);
}
/* .navbar {
  width: 100%;
  margin: 0;
} */

.logo img {
  width: 100%;
  height: 100%;
}
.links {
  display: flex;
  gap: 80px;
}

.links a {
  color: white;
  text-decoration: none;
}

.bookOnlineWhite {
  background: black;
  color: white;
  height: 62px;
  border-radius: 6px;
  padding: 10px;
  border: none;
}

.links a.active span {
  text-decoration: underline;
  font-weight: 700;
}

#cart {
  margin-right: 25px;
  width: 60%;
  position: relative;
}

.cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 10px;
  transform: translate(-125%, 80%);
}

.lang-option {
  color: black;
}

.burger-menu {
  display: none;
}

.burger-icon {
  display: none;
}

.bookOnlineWhite {
  background: black;
  color: white;
  height: 62px;
  border-radius: 6px;
  padding: 10px;
  border: none;
}

/* Hide the book online button for larger screens (inside burger menu) */
.bookOnlineWhite {
  display: none;
}

/* Show book online button in the navbar for larger screens */
.desktop-bookOnline {
  display: block;
}

.bookAndCart {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

.bookAndCart a {
  text-decoration: none;
}

.language-selector {
  margin-left: 20px;

  select {
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #a6a6a6;
    font-size: 14px;
    background-color: transparent;
    color: white;
  }

  select:focus {
    outline: none;
    border-color: #607D9B;
  }
}


@media screen and (max-width: 1100px) {
  .bm-overlay .bm-menu {
    width: 100% !important;
  }

  .burger-menu {
    display: block;
  }

  .burger-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  .bm-burger-bars {
    background-color: black !important;
  }

  .header--dark .bm-burger-bars {
    background-color: white !important;
  }

  .bm-overlay {
    background: none;
  }

  .burger-nav a {
    color: white;
    text-decoration: none;
    margin-bottom: 15%;
  }

  .links {
    display: none;
  }

  .logo {
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-left: 60px;
  }

  // .logo img {
  //   width: 30%;
  // }

  .burger-nav {
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .burger-nav .nav-item span {
    font-size: 25px;
    font-weight: 600;
  }

  .bm-item-list {
    margin-left: 0%;
  }

  #cart {
    margin: 0px;
    width: 100%;
  }

  .bm-menu {
    background-color: #89d0f0;
  }
  .bm-cross {
    background: white;
  }
  /* Show the BOOK ONLINE button in the burger menu for mobile screens */
  .bookOnlineWhite {
    display: block;
    background: white;
    color: #89d0f0;
  }

  /* Hide the BOOK ONLINE button in the navbar for mobile screens */
  .desktop-bookOnline {
    display: none;
  }

  .bookAndCart {
    display: flex;
    align-items: center;
    gap: 0px;
  }

  .cart-count {
    top: 0;
    right: 0;
    font-size: 10px;
    transform: translate(20%, -20%);
  }

  .bookAndCart {
    gap: 0px;
  }

  #cart {
    width: 100%;
    margin: 0;
  }

  .burger-nav .nav-item span {
    font-size: 22px !important;
    font-weight: 500;
  }

  .language-selector {
    position: relative;
    z-index: 10; // Ensure it is above other elements
  }
}
</style>
