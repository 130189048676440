<template>
  <div class="details container-lg">
    <div class="redirect">
      <router-link to="/brands">
        <span style="color: #9F9F9F">{{ $t('brands') }}</span>
      </router-link> >
      <button
        class="back-button"
        @click="goBack"
      >
        {{ $t('back') }}
      </button> >
      <span style="font-weight: 500; color: black;">{{ $t('product') }}</span>
    </div>
    <div
      v-if="products"
      class="productDetails"
    >
      <div class="productImages">
        <div class="mainImage">
          <img
            :src="selectedImage ? selectedImage : products.images[0]"
            alt="product"
          >
        </div>

        <div class="thumbnailImages">
          <img
            v-for="(imgSrc, index) in products.images"
            :key="index"
            :src="imgSrc"
            :class="{ active: selectedImage ? selectedImage : products.images[0] === imgSrc }"
            alt="thumbnail"
            @click="changeImage(imgSrc)"
          >
        </div>
      </div>

      <div class="productDescription">
        <h2>{{ products.name }}</h2>
        <p style="font-size: 26px; font-weight: 700">
          {{ selectedSize && selectedSize.price !== undefined
            ? selectedSize.price
            : (products && products.sizes && products.sizes.length > 0
              ? products.sizes[0].price
              : products.price) }}€
        </p>
        <p
          v-for="(paragraph, index) in products.description.split('\n\n')"
          :key="index"
        >
          {{ paragraph }}
        </p>
        <div
          v-if="products?.colors"
          style="display: flex; flex-direction: row"
        >
          <router-link
            v-for="color in products.colors"
            :key="color.color"
            :to="{ name: 'ProductDetails', params: { brand: products.brand, id: color.to } }"
            @click="selectedColor = color.color"
          >
            <div
              class="details-color"
              :class="{ active: selectedColor === color.color }"
              :style="`background-color: ${color.color}`"
            />
          </router-link>
        </div>

        <!-- Size selection buttons -->
        <div
          v-if="products.sizes && products.sizes.length > 0"
          class="sizes"
        >
          <button
            v-for="(size, index) in products.sizes"
            :key="index"
            :class="{ active: selectedSize === size.size }"
            @click="selectSize(size)"
            :disabled="size?.outOfStock"
          >
            {{ size.size }}
            <small v-if="size.outOfStock" style="display: block">Out of stock</small>
          </button>
        </div>
        <hr>

        <div class="amount_cart">
          <div class="amount">
            <button
              class="sub"
              @click="removeItem"
            >
              <span style="font-size: 22px; font-weight: 600;">-</span>
            </button>
            {{ items }}
            <button
              class="add"
              @click="addItem"
            >
              <span style="font-size: 20px; font-weight: 600;">+</span>
            </button>
          </div>
          <button
            class="addToCart"
            @click="addItemToCart"
          >
            {{ $t('addToCart') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Tabs for Benefits, How to Use, and Ingredients -->
  <div class="tabs">
    <button
      :class="{ active: activeTab === 'benefits' }"
      @click="activeTab = 'benefits'"
    >
      {{ $t('benefits') }}
    </button>
    <button
      v-if="products.howToUse && products.howToUse.length > 0"
      :class="{ active: activeTab === 'howToUse' }"
      @click="activeTab = 'howToUse'"
    >
      {{ $t('howToUse') }}
    </button>
    <button
      v-if="products.ingredients"
      :class="{ active: activeTab === 'ingredients' }"
      @click="activeTab = 'ingredients'"
    >
      {{ $t('ingredients') }}
    </button>
  </div>

  <!-- Display content based on the active tab -->
  <div class="tabContent">
    <div
      v-if="activeTab === 'benefits'"
      class="container-lg"
    >
      <p
        v-for="(item, index) in products.benefits"
        :key="index"
      >
        {{ item }}
      </p>
    </div>
    <div
      v-if="activeTab === 'howToUse' && products.howToUse && products.howToUse.length > 0"
      class="container-lg"
    >
      <p
        v-for="(item, index) in products.howToUse"
        :key="index"
      >
        {{ item }}
      </p>
    </div>
    <div
      v-if="activeTab === 'ingredients' && products.ingredients"
      class="ingredients container-lg"
    >
      <p
        v-for="(item, index) in products.ingredients.split('\n\n')"
        :key="index"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import shop from "@/api/shop";
import shopAl from '@/api/shopAl';
import language from '@/store/modules/language';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      items: 1,
      selectedImage: '',
      selectedColor: '',
      selectedSize: null,
      activeTab: 'benefits'
    }
  },
  computed: {
    ...mapGetters('shop', ['getCartItems']),
    ...mapGetters('language', ['getLanguage']),
    products() {
    const shopApi = this.getLanguage === "al" ? shopAl : shop; // Choose API based on language
    return shopApi.getProduct(this.$route.params.id)[0];
  },
    selectedPrice() {
    if (this.selectedSize) {
      return this.selectedSize.price;
    }
    if (this.products && this.products.sizes && this.products.sizes.length > 0) {
      return this.products.sizes[0].price;
    }
    return 0;
  }
  },
  watch: {

  },
  created() {
    // Set the default size to the first size if available
    if (this.products && this.products.sizes && this.products.sizes.length > 0) {
      // Filter out out-of-stock sizes
      const inStockSizes = this.products.sizes.filter(size => !size.outOfStock);

      // Set the default size to the first available in-stock size
      if (inStockSizes.length > 0) {
        this.selectedSize = inStockSizes[0];
      } else {
        this.selectedSize = null; // No size available if all are out of stock
      }
    } else {
      this.selectedSize = null; // No size selected if sizes are not available
    }
  },
  methods: {
    ...mapActions('shop', ['addToCart']),
  ...mapActions('language', ['changeLanguage']),
    goBack() {
      this.$router.go(-1);
    },
    addItem() {
      this.items++;
    },
    removeItem() {
      if (this.items > 1) this.items--;
    },
    selectSize(size) {
      this.selectedSize = size;
    },
    addItemToCart() {
      const existingItem = this.getCartItems.find(item => item.id === this.products.id);
      if (existingItem) {
        this.notify(this.$t("itemInCart"), true);
      } else {
        this.addToCart({
          payload: { ...this.products, quantity: this.items, size: this.selectedSize ? this.selectedSize.size : null },
        });
        this.notify(this.$t("itemAddedToCart"));
      }
    },
    changeImage(image) {
      this.selectedImage = image; // Change the main image to the clicked thumbnail
    },
    notify(message, isError = false) {
      toast(message, {
        autoClose: 1000,
        type: isError ? "error" : "success",
      });
    }
  }
}
</script>

<style scoped>
.details {
  padding-top: 80px;
  padding-bottom: 80px;
}
.productDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
.productImages {
  width: fit-content;
}
.mainImage img {
  width: 100%;
  height: 530px;
  border-radius: 15px;
}
.thumbnailImages {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.thumbnailImages img {
  width: 120px;
  height: 120px;
  cursor: pointer;
  border: 1px solid transparent;
}
.thumbnailImages img.active {
  border: 1px solid rgb(36, 36, 36);
  border-radius: 6px;
}
.redirect {
  margin-bottom: 20px;
}
.redirect a{
  text-decoration: none;
  color: black;
}
.productDescription {
  width: 55%;
}
.addToCart {
  height: 46px;
  border-radius: 50px;
  background: black;
  color: white;
  flex: 1;
}
.amount_cart {
  display: flex;
  gap: 20px;
}
.amount {
  background:#F0F0F0;
  display: flex;
  border-radius: 50px;
  justify-content: space-evenly;
  align-items: center;
}
.sub, .add {
  border: none;
  background: none;
  padding: 0 20px;
}

.details-color {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin-right: 10px;
  display: flex;
  border: 2px solid transparent;
  cursor: pointer;
}

/* .details-color.active {
  border: 2px solid black;
} */

.tabs {
  display: flex;
  gap: 193px;
  margin-bottom: 20px;
  justify-content: center;
}

.tabs button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  margin-top: 40px;
  font-size: 18px;
  color: #607D9B;
}

.tabs button.active {
  color: #222222;
}

.tabContent {
  padding-block: 60px;
  background-color: #D3DEE9;
  text-align: center;
}

.tabContent p {
  margin-bottom: 0px;
}

.ingredients {
  text-align: left;
}

.sizes {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.sizes button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.sizes button.active {
  background-color: black;
  color: white;
  border: 1px solid black;
}

.sizes button:hover {
  background-color: #f0f0f0;
  border-color: #888;
}

.back-button {
  background: none;
  border: none;
  color: #607D9B;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}
.back-button:hover {
  color: #222222;
}

@media screen and (max-width: 1000px) {
  .productDetails {
    display: flex;
    flex-direction: column;
  }

  .mainImage {
    width: 100%;
  }

  .thumbnailImages img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border: 1px solid transparent;
    margin-bottom: 15px;
  }

  .productDescription {
    width: 100%;
  }

  .amount_cart {
    display: flex;
    justify-content: space-between;
  }

  .addToCart {
    width: 210px
  }

  .tabs {
    display: flex;
    gap: 0px;
  }

  .tabs button {
    font-size: 13px;
  }

  .details {
    padding-top: 20px;
  }
}

@media screen and (max-width: 470px) {
  .thumbnailImages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 10px;
  }

  .mainImage img {
    height: 100% !important;
  }
}
</style>
